export const TOGGLE_VAT_PERCENT = 'TOGGLE_VAT_PERCENT';
export const SET_MEMBER_COUNT = 'SET_MEMBER_COUNT';
export const SET_CLUBS = 'SET_CLUBS';
export const SET_KIND_FILTER = 'SET_KIND_FILTER';
export const SET_CLUB_MODAL_DOMAIN = 'SET_CLUB_MODAL_DOMAIN';
export const SET_COOKIE_CONSENT = 'SET_COOKIE_CONSENT';

export function setCookieConsent (cookieConsent) {
  return { type: SET_COOKIE_CONSENT, payload: cookieConsent }
}

export function setVatPercent (vatPercent) {
  return { type: TOGGLE_VAT_PERCENT, payload: vatPercent };
}

export function setMemberCount (memberCount) {
  return { type: SET_MEMBER_COUNT, payload: memberCount };
}

export function setClubs (clubs) {
  return { type: SET_CLUBS, payload: clubs };
}

export function setKindFilter (kind) {
  return { type: SET_KIND_FILTER, payload: kind };
}

export function setClubModalDomain (domain) {
  return { type: SET_CLUB_MODAL_DOMAIN, payload: domain };
}
