exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-add-on-page-js": () => import("./../../../src/templates/add-on-page.js" /* webpackChunkName: "component---src-templates-add-on-page-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog-index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-custom-page-js": () => import("./../../../src/templates/custom-page.js" /* webpackChunkName: "component---src-templates-custom-page-js" */),
  "component---src-templates-feature-page-js": () => import("./../../../src/templates/feature-page.js" /* webpackChunkName: "component---src-templates-feature-page-js" */),
  "component---src-templates-legal-landing-page-js": () => import("./../../../src/templates/legal-landing-page.js" /* webpackChunkName: "component---src-templates-legal-landing-page-js" */),
  "component---src-templates-legal-page-js": () => import("./../../../src/templates/legal-page.js" /* webpackChunkName: "component---src-templates-legal-page-js" */),
  "component---src-templates-pricing-page-js": () => import("./../../../src/templates/pricing-page.js" /* webpackChunkName: "component---src-templates-pricing-page-js" */)
}

