import React, { createContext, useEffect, useReducer } from 'react';
import { loadAnalyticsScripts, setupAnalytics } from '../utils/analytics';
import { setCookieConsent } from './actions';
import { initialState, reducer } from './reducer';

export const store = createContext(initialState);
const { Provider } = store;

if (typeof window !== 'undefined') {
  setupAnalytics();
}

export function StateProvider ({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleCookiebotAccept = () => {
    dispatch(setCookieConsent(true));
    loadAnalyticsScripts();
  };

  const handleCookiebotDecline = () => {
    dispatch(setCookieConsent(false));
  };

  useEffect(() => {
    window.addEventListener('CookiebotOnAccept', handleCookiebotAccept);
    window.addEventListener('CookiebotOnDecline', handleCookiebotDecline);

    return () => {
      window.removeEventListener('CookiebotOnAccept', handleCookiebotAccept);
      window.removeEventListener('CookiebotOnDecline', handleCookiebotDecline);
    }
  }, []);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
}
