import siteConfig from 'variant/config.yml';

const config = siteConfig.analytics;

export const setupAnalytics = () => {
  // google analytics
  window.dataLayer = window.dataLayer || [];
  window.gtag = function() {
    window.dataLayer.push(arguments);
  };
  window.gtag('js', new Date());
  window.gtag('config', config.google);

  // facebook pixel, modified based on original facebook pixel code
  /* eslint-disable */
  !(function(f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function() {
      n.callMethod
        ? n.callMethod.apply(n, arguments)
        : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
  })(
    window
  );
  /* eslint-enable */

  window.fbq(`init`, config.facebook);
}

let loaded = false;

export const loadAnalyticsScripts = () => {
 
  if (loaded) {
    return;
  }  
  loaded = true;

  const head = document.getElementsByTagName('head')[0]

  let script = document.createElement('script')

  if (config.googleTagManager) {
    // google tag manager
    script.type = 'text/javascript'
    script.async = true;
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${config.googleTagManager}');
    `
    head.appendChild(script);
  }

  // google analytics
  script = document.createElement('script')
  script.type = 'text/javascript'
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${config.google}`
  head.appendChild(script);

  // facebook pixel
  script = document.createElement('script')
  script.type = 'text/javascript'
  script.async = true;
  script.src = 'https://connect.facebook.net/en_US/fbevents.js'
  head.appendChild(script);
}
