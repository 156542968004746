import { TOGGLE_VAT_PERCENT, SET_MEMBER_COUNT, SET_CLUBS, SET_KIND_FILTER, SET_CLUB_MODAL_DOMAIN, SET_COOKIE_CONSENT } from './actions';
import siteConfig from 'variant/config.yml';
import prices from 'variant/prices.yml';

const { vat } = siteConfig;

let useVat = vat.includeByDefault;

if (typeof window !== 'undefined' && window.localStorage) {
  useVat = window.localStorage.getItem('useVat') === 'true'
}

const memberCount = prices.plans.bronze.maxMembers || prices.plans.silver.unit;

export const initialState = {
  vatPercent: useVat ? vat.percent : 0,
  memberCount,
  clubs: [],
  kindFilter: '',
  clubModalDomain: null,
  cookieConsent: false
};

export function reducer (state, action) {
  let newState;
  switch (action.type) {
    case TOGGLE_VAT_PERCENT:
      newState = {
        ...state,
        vatPercent: action.payload
      };
      break;

    case SET_MEMBER_COUNT:
      newState = {
        ...state,
        memberCount: action.payload
      };
      break;

    case SET_CLUBS:
      newState = {
        ...state,
        clubs: action.payload
      };
      break;

    case SET_KIND_FILTER:
      newState = {
        ...state,
        kindFilter: action.payload
      };
      break;

    case SET_CLUB_MODAL_DOMAIN:
      newState = {
        ...state,
        clubModalDomain: action.payload
      };
      break;

    case SET_COOKIE_CONSENT:
      newState = {
        ...state,
        cookieConsent: action.payload
      }
      break;

    default:
      console.error(`Unkonwn action ${action.type}`);
  }
  // console.table({ action, state, newState });
  return newState;
}

export default reducer;
